import {
  Button,
  Card,
  Container,
  Heading,
  GridCol,
  GridRow,
  Text,
  useLoader,
  Divider,
  Image,
} from '@legalshield/adonis-ux-framework';
import { Status } from '@legalshield/frontend-commons/dist/sdk/entitlements';
import React, { useState, useEffect } from 'react';
import { ToggleControl } from '../Controls/ToggleControl';
import { useGetProfile, useUpdateCommunication, useSmsOptIn } from '../../hooks/useProfile';
import { useGetEntitlements } from '../../hooks/useEntitlements';
import {
  AssociatesCommunicationPreferenceTypeResource,
  CommunicationPreferencesResource,
  CommunicationPreferencesTypeResource,
} from '@legalshield/frontend-commons/dist/sdk/profiles';
import { OptInRequest } from '@legalshield/frontend-commons/src/sdk/profiles';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import notificationIcon from '../../images/MessageNotification.svg';
import { waitInSeconds } from '../../utils/utils';
import './Notifications.scss';
import { Layout } from '../Layout/Layout';

interface CommunicationPreferencesSections {
  associate: boolean;
  identity: boolean;
  legal: boolean;
}

type CommunicationPreferencesResourceKey = keyof CommunicationPreferencesResource;
type CommunicationPreferencesTypeResourceKey =
  | keyof CommunicationPreferencesTypeResource
  | keyof AssociatesCommunicationPreferenceTypeResource;
export enum CommunicationPreferenceValue {
  ASSOCIATE = 'associate',
  IDENTITY = 'identity',
  LEGAL = 'legal',
  PROMOTIONS = 'promotions',
}
export enum CommunicationPreferenceTypeValue {
  ALL = 'all',
  UPDATESANDINCENTIVES = 'updatesAndIncentives',
  TECHNOLOGYANDTOOLS = 'technologyAndTools',
  NEWS = 'news',
  PRODUCT = 'product',
  PROMOTIONS = 'promotions',
  SURVEYSFEEDBACK = 'surveysFeedback',
}
const associateFamily = 'Associate';
const legalFamily = 'Legal';
const identityFamily = 'Identity';

const Notifications: React.FC = () => {
  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.NOTIFICATIONS_BREADCRUMB, link: '/overview/notifications' },
  ];
  const [communicationPreferencesSections, setCommunicationPreferencesSections] =
    useState<CommunicationPreferencesSections>({
      associate: false,
      identity: false,
      legal: false,
    });

  const defaultCommunicationPreferences: CommunicationPreferencesResource = {
    all: true,
    associate: {
      all: true,
      news: true,
      product: true,
      promotions: true,
      surveysFeedback: true,
      technologyAndTools: true,
      updatesAndIncentives: true,
    },
    generalMarketing: {
      all: true,
      news: true,
      product: true,
      promotions: true,
      surveysFeedback: true,
    },
    identity: {
      all: true,
      news: true,
      product: true,
      promotions: true,
      surveysFeedback: true,
    },
    legal: {
      all: true,
      news: true,
      product: true,
      promotions: true,
      surveysFeedback: true,
    },
  };

  const loader = useLoader();
  const isMobileDevice = window.innerWidth <= 576;
  const {
    data: profileData,
    isSuccess: isProfileSuccess,
    isLoading: isProfileLoading,
    isError: isProfileError,
  } = useGetProfile();

  const {
    mutateAsync,
    isLoading: isUpdateCommLoading,
    isError: isUpdateCommError,
    isSuccess: isUpdateCommSuccess,
  } = useUpdateCommunication(profileData?.version);

  const {
    mutate: updateSmsOptIn,
    isLoading: isSmsOptInLoading,
    isError: isSmsOptInError,
    isSuccess: isSmsOptInSuccess,
  } = useSmsOptIn(profileData?.version);

  const {
    data: entitlements,
    isLoading: isEntitlementsLoading,
    isError: isEntitlementsError,
    isSuccess: isEntitlementsSuccess,
  } = useGetEntitlements();

  const updateCommunicationPreferences = async (
    communicationPreference: CommunicationPreferencesResourceKey,
    communicationPreferenceType: CommunicationPreferencesTypeResourceKey,
    toggle: boolean,
  ) => {
    const communicationPreferences: CommunicationPreferencesResource =
      profileData.communicationPreferences ?? defaultCommunicationPreferences;
    if (toggle) {
      communicationPreferences.all = true;
      communicationPreferences[communicationPreference]['all'] = true;
    }
    communicationPreferences[communicationPreference][communicationPreferenceType] = toggle;
    try {
      await mutateAsync(communicationPreferences);
      await waitInSeconds(5);
      loader.Blank();
    } catch (error) {}
  };

  const updateSmsOptInPreferences = async (toggle: boolean) => {
    const smsOptInPreferences: OptInRequest = { optIn: profileData.smsOptIn ?? toggle };
    try {
      smsOptInPreferences.optIn = !toggle;
      await updateSmsOptIn(smsOptInPreferences);
      await waitInSeconds(5);
      loader.Blank();
    } catch (error) {}
  };

  const updateAllCommunicationPreferences = async (toggle: boolean) => {
    const communicationPreferences: CommunicationPreferencesResource =
      profileData.communicationPreferences ?? defaultCommunicationPreferences;
    try {
      communicationPreferences.all = !toggle;
      await mutateAsync(communicationPreferences);
      await waitInSeconds(5);
      loader.Blank();
    } catch (error) {}
  };

  const setSectionsVisibility = () => {
    const isAssociateVisible = !!entitlements.find(
      (e) => e.status === Status.ACTIVE && e.product.productFamily == associateFamily,
    );
    const isIdentityVisible = !!entitlements.find(
      (e) => e.status === Status.ACTIVE && e.product.productFamily == identityFamily,
    );
    const isLegalVisible = !!entitlements.find(
      (e) => e.status === Status.ACTIVE && e.product.productFamily == legalFamily,
    );
    setCommunicationPreferencesSections({
      associate: isAssociateVisible,
      identity: isIdentityVisible,
      legal: isLegalVisible,
    });
  };

  useEffect(() => {
    if (isProfileError || isEntitlementsError || isSmsOptInError || isUpdateCommError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isProfileLoading || isEntitlementsLoading || isSmsOptInLoading || isUpdateCommLoading) {
      loader.Loading();
    }
    if (isProfileSuccess && isEntitlementsSuccess) {
      loader.Blank();
      setSectionsVisibility();
    }
    if (isSmsOptInSuccess || isUpdateCommSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProfileError,
    isProfileLoading,
    isProfileSuccess,
    isEntitlementsLoading,
    isEntitlementsError,
    isEntitlementsSuccess,
    isSmsOptInLoading,
    isSmsOptInError,
    isSmsOptInSuccess,
    isUpdateCommLoading,
    isUpdateCommError,
    isUpdateCommSuccess,
  ]);

  return (
    <Layout crumbs={crumbs} title={string_table.NOTIFICATIONS_TITLE} subtitle={string_table.NOTIFICATIONS_DESCRIPTION}>
      <Divider classNames={['py-4']} />
      <Container flexbox justifyContent="space-between" data-testid="noticesContainer">
        <Container style={{ maxWidth: 424 }}>
          <Container>
            <GridRow classNames={['noTopMargin']}>
              <GridCol>
                <Container classNames={['smsOptIn']} data-testid="smsOptInContainer">
                  <Heading classNames={['pb-4']} as="T20" text={string_table.NOTIFICATIONS_SMS_OPT_IN_TITLE} />
                  <ToggleControl
                    alignItems="center"
                    divider={true}
                    label={string_table.NOTIFICATIONS_SMS_OPT_IN}
                    subText={string_table.NOTIFICATIONS_SMS_OPT_IN_TOOLTIP}
                    onToggle={() => updateSmsOptInPreferences(profileData?.smsOptIn ?? true)}
                    toggled={profileData?.smsOptIn ?? true}
                    disabled={isProfileLoading}
                  />
                </Container>
              </GridCol>
            </GridRow>
          </Container>
          {communicationPreferencesSections.legal && (
            <Container classNames={['py-0', 'mt-10']}>
              <GridRow>
                <GridCol>
                  <Container classNames={['mt-2', 'prefLegalServices']} data-testid="prefLegalServicesContainer">
                    <Heading classNames={['pb-4']} as="T20" text={string_table.NOTIFICATIONS_LEGAL_SERVICES} />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_LEGAL_NEWS}
                      subText={string_table.NOTIFICATIONS_LEGAL_NEWS_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.LEGAL,
                          CommunicationPreferenceTypeValue.NEWS,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.legal.news
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.legal.news ?? true}
                      disabled={isProfileLoading}
                    />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_LEGAL_PRODUCT_UPDATES}
                      subText={string_table.NOTIFICATIONS_LEGAL_PRODUCT_UPDATES_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.LEGAL,
                          CommunicationPreferenceTypeValue.PRODUCT,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.legal.product
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.legal.product ?? true}
                      disabled={isProfileLoading}
                    />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_LEGAL_PROMOTIONS}
                      subText={string_table.NOTIFICATIONS_LEGAL_PROMOTIONS_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.LEGAL,
                          CommunicationPreferenceTypeValue.PROMOTIONS,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.legal.promotions
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.legal.promotions ?? true}
                      disabled={isProfileLoading}
                    />
                  </Container>
                </GridCol>
              </GridRow>
            </Container>
          )}
          {communicationPreferencesSections.identity && (
            <Container classNames={['py-0', 'mt-10']}>
              <GridRow>
                <GridCol>
                  <Container classNames={['mt-2', 'prefIDServices']} data-testid="prefIDServicesContainer">
                    <Heading classNames={['pb-4']} as="T20" text={string_table.NOTIFICATIONS_ID_SERVICES} />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_ID_NEWS}
                      subText={string_table.NOTIFICATIONS_ID_NEWS_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.IDENTITY,
                          CommunicationPreferenceTypeValue.NEWS,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.identity.news
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.identity.news ?? true}
                      disabled={isProfileLoading}
                    />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_ID_PRODUCT_UPDATES}
                      subText={string_table.NOTIFICATIONS_ID_PRODUCT_UPDATES_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.IDENTITY,
                          CommunicationPreferenceTypeValue.PRODUCT,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.identity.product
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.identity.product ?? true}
                      disabled={isProfileLoading}
                    />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_ID_PROMOTIONS}
                      subText={string_table.NOTIFICATIONS_ID_PROMOTIONS_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.IDENTITY,
                          CommunicationPreferenceTypeValue.PROMOTIONS,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.identity.promotions
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.identity.promotions ?? true}
                      disabled={isProfileLoading}
                    />
                  </Container>
                </GridCol>
              </GridRow>
            </Container>
          )}
          {communicationPreferencesSections.associate && (
            <Container classNames={['py-0', 'mt-10']}>
              <GridRow>
                <GridCol>
                  <Container
                    classNames={['mt-2', 'prefAssociateServices']}
                    data-testid="prefAssociateServicesContainer"
                  >
                    <Heading classNames={['pb-4']} as="T20" text={string_table.NOTIFICATIONS_ASSOCIATE_SERVICES} />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_ASSOCIATE_TECHNOLOGY}
                      subText={string_table.NOTIFICATIONS_ASSOCIATE_TECHNOLOGY_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.ASSOCIATE,
                          CommunicationPreferenceTypeValue.TECHNOLOGYANDTOOLS,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.associate.technologyAndTools
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.associate.technologyAndTools ?? true}
                      disabled={isProfileLoading}
                    />
                    <ToggleControl
                      alignItems="center"
                      divider={true}
                      label={string_table.NOTIFICATIONS_ASSOCIATE_UPDATES_AND_INCENTIVES}
                      subText={string_table.NOTIFICATIONS_ASSOCIATE_UPDATES_AND_INCENTIVES_TOOLTIP}
                      onToggle={() =>
                        updateCommunicationPreferences(
                          CommunicationPreferenceValue.ASSOCIATE,
                          CommunicationPreferenceTypeValue.UPDATESANDINCENTIVES,
                          profileData?.communicationPreferences
                            ? !profileData?.communicationPreferences?.associate.updatesAndIncentives
                            : false,
                        )
                      }
                      toggled={profileData?.communicationPreferences?.associate.updatesAndIncentives ?? true}
                      disabled={isProfileLoading}
                    />
                  </Container>
                </GridCol>
              </GridRow>
            </Container>
          )}
          <Container classNames={['pt-0', 'mt-6']}>
            <GridRow>
              <GridCol>
                <Container classNames={['prefUnsubscribes']} data-testid="prefUnsubscribesContainer">
                  <Container flexbox flexDirection="column">
                    <Button
                      id="unsubscribeAllBtn"
                      variant={'secondary'}
                      label={string_table.NOTIFICATIONS_UNSUBSCRIBE_ALL_BUTTON}
                      onClick={() =>
                        updateAllCommunicationPreferences(
                          profileData?.communicationPreferences ? profileData?.communicationPreferences?.all : true,
                        )
                      }
                    />
                  </Container>
                  <Container classNames={['mt-4']} style={{ textAlign: 'center' }}>
                    <Text textSize="tiny" text={string_table.NOTIFICATIONS_UNSUBSCRIBE_DESCRIPTION}></Text>
                  </Container>
                </Container>
              </GridCol>
            </GridRow>
          </Container>
        </Container>
        {!isMobileDevice && (
          <Container>
            <Container style={{ maxWidth: 312 }}>
              <GridCol>
                <Card>
                  <Card.Content>
                    <Container data-testid="infoBoxContainer">
                      <Image src={notificationIcon} />
                      <Container classNames={['py-4']}>
                        <Heading as="T16" text={string_table.INFO_BOX_TITLE_NOTIFICATIONS} classNames={['pb-3']} />
                      </Container>
                      <Text text={string_table.INFO_BOX_MESSAGE_NOTIFICATIONS} />
                    </Container>
                  </Card.Content>
                </Card>
              </GridCol>
            </Container>
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default Notifications;
