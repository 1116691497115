import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Alert } from '@legalshield/adonis-ux-framework';

interface MembershipAlertsProps {
  showAfterPurchaseMessage: boolean;
  showCallMessage: boolean;
  showDeclinedPayment: boolean;
  setShowDeclinedPayment: (show: boolean) => void;
}

export const MembershipAlerts: FC<MembershipAlertsProps> = ({
  showAfterPurchaseMessage = false,
  showCallMessage = false,
  showDeclinedPayment = false,
  setShowDeclinedPayment,
}) => (
  <>
    {showAfterPurchaseMessage && (
      <Alert severity="information" icon="alert_information" bold={false} classNames={['mt-1']}>
        {string_table.PRODUCTS_AFTER_PURCHASE}
      </Alert>
    )}
    {showCallMessage && (
      <Alert severity="warning" icon="alert_warning" bold={false} classNames={['mt-1']}>
        {string_table.PRODUCTS_SHOULD_CALL.replace('%phone%', pplsi.phoneNumber)}
      </Alert>
    )}
    {showDeclinedPayment && (
      <Alert
        severity="error"
        icon="warning_triangle_warning"
        bold={false}
        title={`${string_table.MEMBERSHIPS_PAYMENT_DECLINED_ALERT_TITLE}.`}
        classNames={['mt-2', 'mb-5']}
        dismissible={true}
        onDismiss={() => setShowDeclinedPayment(false)}
      >
        <div className="pt-2">
          <span className="lsux-text--medium">{`${string_table.MEMBERSHIPS_PAYMENT_DECLINED_ALERT_BODY}. `}</span>
          <Link to="/wallet" className="lsux-text--medium">
            {`${string_table.MEMBERSHIPS_PAYMENT_DECLINED_ALERT_BODY_CTA}`}
          </Link>
          <span className="lsux-text--medium">.</span>
        </div>
      </Alert>
    )}
  </>
);
