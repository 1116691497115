import React, { FC } from 'react';
import { Button, Container, Spinner } from '@legalshield/adonis-ux-framework';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Layout } from '../../Layout/Layout';
import { ProductInfo } from './ProductInfo';
import { SupplementInfo } from './SupplementInfo';
import { useGetSubscriptionBySubscriptionId } from '../hooks/useSubscription';

const ProductDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: subscription, isLoading: subscriptionsLoading } = useGetSubscriptionBySubscriptionId(id);

  if (subscriptionsLoading) return <Spinner blocking spinnerSize="xlarge" />;
  if (!subscription) {
    return <Navigate to="/payments" replace />;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.PAYMENT_TITLE, link: '/payments' },
    { label: string_table.PRODUCT_DETAILS_HEADING, link: `/payments/details/${id}` },
  ];

  return (
    <Layout id="product-details" crumbs={crumbs} title={string_table.PRODUCT_DETAILS_HEADING}>
      <ProductInfo subscription={subscription} />
      <SupplementInfo subscription={subscription} />
      <Container flexbox={true} classNames={['align-items-end', 'mb-5']}>
        <Button
          pplsiEventId="back-to-subscriptions"
          classNames={['ml-auto']}
          label="Done"
          stretch
          variant="primary"
          widthLong="160px"
          onClick={() => navigate('/payments')}
        />
      </Container>
    </Layout>
  );
};

export default ProductDetails;
