import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Divider, GridCol, Heading, Text, useLoader } from '@legalshield/adonis-ux-framework';
import { Status } from '@legalshield/frontend-commons/dist/sdk/entitlements';

import Config from '../../config';
import URLs from '../../utils/url';
import { EntitlementPresenter } from '../../presenters';
import { Layout } from '../Layout/Layout';
import { useGetEntitlements } from '../../hooks/useEntitlements';

const Resources: FC = () => {
  const [entitlementsPresenter, setEntitlementsPresenter] = useState<EntitlementPresenter[]>([]);
  const [hasNationalPlans, setHasNationalPlans] = useState(false);
  const { data: entitlements, isLoading, isError, isSuccess } = useGetEntitlements();
  const loader = useLoader();

  useEffect(() => {
    if (entitlements) {
      const entitlementPresenters = entitlements
        .filter((e) => {
          return e.status === Status.ACTIVE;
        })
        .map((e) => new EntitlementPresenter(e));
      setEntitlementsPresenter(entitlementPresenters);
    }
  }, [entitlements]);

  useEffect(() => {
    if (isError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isLoading) {
      loader.Loading();
    }
    if (isSuccess) {
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isLoading, isSuccess]);

  useEffect(() => {
    for (let index = 0; index < entitlementsPresenter.length; index++) {
      if (entitlementsPresenter[index].isNationalProduct()) {
        setHasNationalPlans(true);
        break;
      }
    }
  }, [entitlementsPresenter]);

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.RESOURCES_TITLE, link: '/resources' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.RESOURCES_TITLE} subtitle={string_table.RESOURCES_DESCRIPTION}>
      <Divider classNames={['py-4']} />
      {!isLoading && (
        <GridCol classNames={['mb-4']}>
          <Card
            cardHeight="100%"
            onClick={() => (window.location.href = URLs.getLSAppUrl(Config.currentEnv.apps.forms))}
          >
            <Card.Content>
              <img
                src={'https://design.api.' + pplsi.realmDomain + '/assets/logos/legalshield-logo.svg'}
                alt="LegalShield Logo"
              />
              <Heading as="T20" text={string_table.RESOURCES_FORMS_LS} />
              <Text as="p">{string_table.RESOURCES_FORMS_LS_DESCRIPTION}</Text>
              <Card.Actions align={'right'}>
                <Button
                  label={string_table.RESOURCES_FORMS_LS}
                  onClick={() => (window.location.href = URLs.getLSAppUrl(Config.currentEnv.apps.forms))}
                />
              </Card.Actions>
            </Card.Content>
          </Card>
        </GridCol>
      )}
      {!isLoading && (
        <GridCol classNames={['mb-4']}>
          <Card cardHeight="100%" onClick={() => window.open('https://legalshield.perkspot.com/login', '_new')}>
            <Card.Content>
              <img
                src={
                  'https://avatars.legalshield.com/accounts/en-us/default/0/36c94a3e-aa67-4178-bf8d-d27f689874de.png'
                }
                alt="Member Perks Logo"
              />
              <Heading as="T20" text={string_table.RESOURCES_MEMBER_PERKS} />
              <Text as="p">{string_table.RESOURCES_MEMBER_PERKS_DESCRIPTION}</Text>
              <Card.Actions align={'right'}>
                <Button
                  label={string_table.RESOURCES_MEMBER_PERKS}
                  onClick={() => window.open('https://legalshield.perkspot.com/login', '_new')}
                />
              </Card.Actions>
            </Card.Content>
          </Card>
        </GridCol>
      )}
    </Layout>
  );
};

export default Resources;
