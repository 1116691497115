import React, { createContext, useContext, useReducer } from 'react';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import PrecancelModal from './PrecancelModal';
import { usePaymentLastFour } from '../hooks/utils/usePaymentLastFour';

type PrecancelModalState = {
  showModal: boolean;
  subscription: ISubscriptionResource | null;
  paymentMethodId: string | null;
  handleClickPayAndRenew: (() => void) | null;
};

type ShowModalAction = {
  type: 'SHOW_MODAL';
  payload: { subscription: ISubscriptionResource; paymentMethodId: string; handleClickPayAndRenew: () => void };
};

type HideModalAction = {
  type: 'HIDE_MODAL';
};

type PrecancelModalAction = ShowModalAction | HideModalAction;

const precancelModalReducer = (state: PrecancelModalState, action: PrecancelModalAction): PrecancelModalState => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        showModal: true,
        subscription: action.payload.subscription,
        paymentMethodId: action.payload.paymentMethodId,
        handleClickPayAndRenew: action.payload.handleClickPayAndRenew,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: false,
        subscription: null,
        paymentMethodId: null,
        handleClickPayAndRenew: null,
      };
    default:
      return state;
  }
};

export const PrecancelModalContext = createContext<{
  state: PrecancelModalState;
  dispatch: React.Dispatch<PrecancelModalAction>;
}>({
  state: {
    showModal: false,
    subscription: null,
    paymentMethodId: null,
    handleClickPayAndRenew: () => {},
  },
  dispatch: () => undefined,
});

type PrecancelModalProviderProps = {
  children: React.ReactNode;
};

export const PrecancelModalProvider: React.FC<PrecancelModalProviderProps> = ({ children }) => {
  const initialState: PrecancelModalState = {
    showModal: false,
    subscription: null,
    paymentMethodId: null,
    handleClickPayAndRenew: () => {},
  };
  const [state, dispatch] = useReducer(precancelModalReducer, initialState);
  const lastFourDigits = usePaymentLastFour(state?.paymentMethodId, state?.subscription?.status);

  return (
    <PrecancelModalContext.Provider value={{ state, dispatch }}>
      {children}
      {state.showModal && (
        <PrecancelModal
          subscription={state.subscription}
          lastFour={lastFourDigits}
          onClose={() => dispatch({ type: 'HIDE_MODAL' })}
        />
      )}
    </PrecancelModalContext.Provider>
  );
};

export const usePrecancelModal = () => {
  const context = useContext(PrecancelModalContext);
  if (!context) {
    throw new Error('usePrecancelModal must be used within a PrecancelModalProvider');
  }
  return context;
};
