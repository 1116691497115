import React, { FC, Key, useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import cn from 'classnames';
import { Container, GridCol, Heading } from '@legalshield/adonis-ux-framework';
import { Status } from '@legalshield/frontend-commons/dist/sdk/entitlements';

import AssociateCard from '../AssociateCard/AssociateCard';
import CardGrid from '../../Card/CardGrid';
import { Entitlement, PlanFamilies } from './Entitlement/Entitlement';
import { EntitlementPresenter } from '../../../presenters';
import { LinkToAccess } from '../../LinkToAccess/LinkToAccess';
import { MembershipAlerts } from './Alerts/MembershipAlerts';
import { MembershipsEmptyState } from './EmptyState/EmptyState';
import { SkeletonCard } from '../../SkeletonCard/SkeletonCard';
import { camelize } from '../../../utils/utils';

import IDShieldOffer from './IDShieldOffer/IDShieldOffer';

import './Memberships.scss';

export interface MembershipsProps {
  products: EntitlementPresenter[];
  associates: EntitlementPresenter[];
  isLoading?: boolean;
  isSuccess?: boolean;
}

const Memberships: FC<MembershipsProps> = ({ products, associates, isLoading, isSuccess }) => {
  const [showNoProductsCopy, setShowNoProductsCopy] = useState(false);
  const [showAfterPurchaseMessage, setShowAfterPurchaseMessage] = useState(false);
  const [showCallMessage, setShowCallMessage] = useState(false);
  const [orderedPlanFamilies, setOrderedPlanFamilies] = useState({} as PlanFamilies);
  const [showDeclinedPayment, setShowDeclinedPayment] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setHasLoaded(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const purchaseInfoCookieParsed = useMemo(() => {
    return JSON.parse(Cookies.get('purchase_info') || '{}');
  }, []);

  const afterPurchaseMessage = useCallback(() => {
    if (purchaseInfoCookieParsed?.purchase_complete) {
      const ts = Math.round(new Date().getTime() / 1000);
      const tsYesterday = ts - 24 * 3600;
      if (purchaseInfoCookieParsed?.purchase_complete - tsYesterday > 0) {
        setShowAfterPurchaseMessage(true);
      } else {
        setShowCallMessage(true);
      }
    } else {
      setShowNoProductsCopy(true);
    }
  }, [purchaseInfoCookieParsed?.purchase_complete]);

  const orderingPlans = useCallback(() => {
    setShowCallMessage(false);
    setShowNoProductsCopy(false);
    setShowAfterPurchaseMessage(false);
    const ordered: PlanFamilies = {};
    products.forEach((product) => {
      const key = camelize(product?.entitlement?.product?.productFamily);
      if (ordered[key]) {
        ordered[key].push(product);
        return;
      }
      ordered[key] = [] as EntitlementPresenter[];
      ordered[key].push(product);
    });
    Object.keys(ordered).forEach((key) => {
      ordered[key]
        .sort((a, b) => {
          return new Date(a.entitlement.updatedAt).getTime() - new Date(b.entitlement.updatedAt).getTime();
        })
        .reverse();
    });
    setOrderedPlanFamilies(ordered);
  }, [products]);

  useEffect(() => {
    if (products.length === 0 && associates.length === 0) {
      afterPurchaseMessage();
    } else {
      orderingPlans();
      setShowNoProductsCopy(false);
    }
  }, [products, afterPurchaseMessage, orderingPlans]);

  const hasSuspendedEntitlements = useMemo(() => {
    return Object.keys(orderedPlanFamilies).some((family) => {
      return orderedPlanFamilies[family].some((presenter) => {
        return presenter.entitlement.status === Status.SUSPENDED;
      });
    });
  }, [orderedPlanFamilies]);

  useEffect(() => {
    setShowDeclinedPayment(true);
    if (hasSuspendedEntitlements) {
      setShowDeclinedPayment(true);
    } else {
      setShowDeclinedPayment(false);
    }
  }, [hasSuspendedEntitlements]);

  const MembershipCards = () => {
    if (showNoProductsCopy)
      return (
        <CardGrid>
          <MembershipsEmptyState />
        </CardGrid>
      );

    return (
      <CardGrid>
        {associates.length ? (
          <GridCol>
            <AssociateCard count={associates.length} />
          </GridCol>
        ) : null}
        {Object.keys(orderedPlanFamilies).map((family) => {
          return orderedPlanFamilies[family].map((presenter: EntitlementPresenter, index: Key | null | undefined) => (
            <GridCol key={index}>
              <Entitlement
                key={index}
                index={index}
                presenter={presenter}
                family={family}
                orderedPlanFamilies={orderedPlanFamilies}
              />
            </GridCol>
          ));
        })}
      </CardGrid>
    );
  };

  return (
    <Container className={cn('memberships-list', { 'memberships-list--expanded': hasLoaded })}>
      <MembershipAlerts
        showAfterPurchaseMessage={showAfterPurchaseMessage}
        showCallMessage={showCallMessage}
        showDeclinedPayment={showDeclinedPayment}
        setShowDeclinedPayment={setShowDeclinedPayment}
      />
      <Heading as="T20" text={string_table.MEMBERSHIPS_TITLE} classNames={['mt-4']} />
      {!!isLoading ? <SkeletonCard /> : <MembershipCards />}
      <LinkToAccess />

      {/*
        THIS IS A TEMPORARY TEST DONE BY THE PRICING AGILITY SQUAD THAT SHOULD RUN TRHOUGH APPROXIMATELY
        SEPTEMBER 30, 2024. THE ACTUAL END DATE IS IN THE VARIATION FOR THE FLAG IN LAUNCH DARKLY.
        IF YOU SEE THIS CODE AFTER THAT DATE PLEASE FEEL FREE TO REMOVE IT OR REACH OUT TO THE PRICING AGILITY
        SQUAD AND REFERENCE THIS TICKET: https://legalshield.atlassian.net/browse/SL-3195
      */}
      <IDShieldOffer />
    </Container>
  );
};

export default Memberships;
