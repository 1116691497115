import React, { Fragment } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Container, GridCol, Heading, Icon, Text } from '@legalshield/adonis-ux-framework';

import CardGrid from '../../../Card/CardGrid';
import { IDShieldInfoModal } from './IDShieldOfferModal';
import { PlanControl } from '../../../Controls/PlanControl';

const IDShieldOffer = () => {
  const { idShieldOfferExperiment } = useFlags();
  const bundled = idShieldOfferExperiment?.type === 'bundled';
  const showExperiment = ['bundled', 'non-bundled'].includes(idShieldOfferExperiment?.type);

  if (!showExperiment) {
    return <Fragment />;
  }

  return (
    <Container>
      <Heading as="T20" text="You may also like" classNames={['mt-4']} />
      <CardGrid>
        <GridCol>
          <div className="lsux-card accounts-card p-5">
            <Icon name="apps_id_shield" size="xlarge" />
            <Heading as="T20" text="IDShield" classNames={['mt-4']} />
            <PlanControl planTitle="Explore IDShield" showExternalLink={false} classNames={['px-4', 'pt-4']}>
              <Text classNames={['accounts-card__text']}>Get the best 24/7 identity theft protection.</Text>
              {bundled && (
                <>
                  <Text classNames={['pt-3', 'accounts-card__text']} as="p">
                    Add IDShield to your membership and save!
                  </Text>
                  {idShieldOfferExperiment.expiration && (
                    <Text classNames={['pt-3', 'accounts-card__text']} as="p">
                      <>Offer expires: {idShieldOfferExperiment.expiration}</>
                    </Text>
                  )}
                </>
              )}
              <IDShieldInfoModal />
            </PlanControl>
          </div>
        </GridCol>
      </CardGrid>
    </Container>
  );
};

export default IDShieldOffer;
