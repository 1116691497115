/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigation, NavigationItem, useViewportSize } from '@legalshield/adonis-ux-framework';

import RealmUtility from '../../utils/realm';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

export interface NavButton extends NavigationItem {
  accessLinkURL?: string;
  name: string;
  icon?: string;
  icon1?: string;
  iconName?: string;
  idsLinkText?: string;
  idsLinkURL?: string;
  imageSrc?: string;
  legalLinkText?: string;
  legalLinkURL?: string;
  linkText?: string;
  to?: string;
  data: string;
  text?: string;
}

const overview: NavButton = {
  data: '/overview',
  iconName: 'menu_more_grid_big',
  name: 'Overview',
};

const profile: NavButton = {
  data: '/profile',
  iconName: 'nav_profile',
  name: 'Profile',
  text: string_table.SETTINGS_PROFILE_DESCRIPTION,
};

const payments: NavButton = {
  data: '/payments',
  iconName: 'interface_credit_card_01',
  name: 'Payments',
  text: string_table.ADD_OR_EDIT_PAYMENT_METHOD,
};

const security: NavButton = {
  data: '/security',
  iconName: 'interface_lock',
  name: 'Security',
  text: string_table.SETTINGS_SECURITY_DESCRIPTION,
};

const resources: NavButton = {
  data: '/resources',
  iconName: 'interface_book',
  name: 'Resources',
  text: string_table.SETTINGS_RESOURCES_DESCRIPTION,
};

const notifications: NavButton = {
  data: '/notifications',
  iconName: 'comm_bell',
  name: 'Notifications',
  text: string_table.SETTINGS_NOTIFICATIONS_DESCRIPTION,
};

const faq: NavButton = {
  data: '/faq',
  iconName: 'nav_help',
  name: 'FAQ',
  text: 'Frequently asked questions about our products',
};

export const defaultNavItems: NavButton[] = [overview, profile, security];
export const userNavItems: NavButton[] = [overview, profile, payments, security, resources, notifications, faq];

export const defaultNavButtons: NavButton[] = [profile, security];
export const userNavButtons: NavButton[] = [profile, payments, security, resources, notifications, faq];

export const NavigationBar: FC = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [activeData, setActiveData] = useState('');
  const [navItems, setNavItems] = useState<NavigationItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const realm = RealmUtility.getRealm();

  const { width } = useViewportSize();
  const breakpoint = 1023;

  const openClicked = () => setNavigationOpen(true);
  const closeClicked = () => setNavigationOpen(false);

  const noSidebarPaths = ['/setup', '/page-not-found'];

  if (noSidebarPaths.includes('/overview')) noSidebarPaths.splice(noSidebarPaths.indexOf('/overview'), 1);
  if (noSidebarPaths.includes('/overview/profile'))
    noSidebarPaths.splice(noSidebarPaths.indexOf('/overview/profile'), 1);
  if (noSidebarPaths.includes('/overview/notifications'))
    noSidebarPaths.splice(noSidebarPaths.indexOf('/overview/notifications'), 1);
  if (!window.location.pathname.includes('/setup')) {
    ads_set_hamburger_callback(openClicked);
  }

  useEffect(() => {
    const userId = pplsi?.authNPayload?.sub || '';
    if (userId !== '' && location.pathname !== '/') {
      const navItems = realm === Realm.User ? userNavItems : defaultNavItems;
      setNavItems(navItems);
    }
  }, [location.pathname]);

  const handleButtonClick = (_: number, data: string) => {
    navigate(data);
    setNavigationOpen(false);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (pplsi.authNPayload?.sub_type !== 'Partner') {
        setActiveData('/products');
      }
      if (pplsi.authNPayload?.sub_type === 'Partner') {
        setActiveData('/profile');
      }
    } else {
      setActiveData(location.pathname);
    }
  }, [location, setActiveData]);

  // If there are no navigation items, do not render the component
  if (!navItems) return <Fragment />;

  // If the current path is in the noSidebarPaths array, do not render the component
  if (!noSidebarPaths.every((path) => !window.location.pathname.includes(path))) return <Fragment />;

  return (
    <Navigation
      activeData={activeData}
      isOpen={navigationOpen}
      items={navItems}
      classNames={['lsux-navigation', 'main-nav']}
      navigationType={width > breakpoint ? 'pane' : 'slideout'}
      onClick={handleButtonClick}
      onClose={closeClicked}
    />
  );
};
