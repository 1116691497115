import { FriendlyName, ProductTier } from '@legalshield/frontend-commons/dist/sdk/products';
import Cookies from 'js-cookie';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';

const locale = Cookies.get('market') || '';

export const getLocale = () => {
  const language = locale?.split('-')[0]?.toLowerCase();
  return language || 'en';
};

export const getFriendlyNameByLocale = (friendlyNames: FriendlyName[] | null | undefined, preferredLocale: string) => {
  if (!friendlyNames || friendlyNames === undefined) return '';

  return (
    friendlyNames.find((friendlyName) => friendlyName.locale?.toLowerCase() === preferredLocale)?.name ||
    friendlyNames.find((friendlyName) => friendlyName.locale?.toLowerCase() === 'en')?.name ||
    ''
  );
};

const findMatchingTier = (tiers: ProductTier[], tierName: string) => {
  return tiers.find((tier) => tier.name === tierName);
};

const currentLocale = getLocale();

const getFriendlyTierName = (tier: ProductTier, productName: string) => {
  const friendlyName =
    getFriendlyNameByLocale(tier.friendlyNames, currentLocale) || getFriendlyNameByLocale(tier.friendlyNames, 'en-us');

  if (friendlyName) {
    return `${productName} ${friendlyName}`;
  }

  return '';
};

export const getFriendlyProductName = (subscription: ISubscriptionResource) => {
  const offerItems = subscription?.offer?.offerItems;
  const hasSupplement = offerItems.some((offerItem) => offerItem.type === 'SUPPLEMENT');
  const product = subscription?.productBrochure;
  const productTier = product?.tier;

  if (!offerItems || !product || !productTier) {
    return '';
  }

  for (const offerItem of offerItems) {
    const offerProductTier = offerItem.productDetail?.productTier;

    if (offerProductTier) {
      const matchedTier = findMatchingTier(productTier, offerProductTier);

      if (matchedTier) {
        const friendlyProductName =
          getFriendlyNameByLocale(product.friendlyNames, currentLocale) ||
          getFriendlyNameByLocale(product.friendlyNames, 'en-us') ||
          '';

        const tierName = getFriendlyTierName(matchedTier, friendlyProductName) || matchedTier.name;

        const supplementText = hasSupplement ? ' w/ Supplements' : '';

        return `${tierName}${supplementText}`;
      }
    }
  }

  return '';
};
