import React from 'react';
import { Container, Divider, Spinner } from '@legalshield/adonis-ux-framework';

import { ISubscriptionResource } from './hooks/utils/subscription-utils';
import { Layout } from '../Layout/Layout';
import { PrecancelModalProvider } from './PrecancelModal/PrecancelModalContext';
import { SubscriptionCard } from './SubscriptionCard';
import { useGetSubscriptionsByIdentityId } from './hooks/useSubscriptions';

const Payments: React.FC = () => {
  const { data: subscriptions, isLoading: subscriptionsLoading } = useGetSubscriptionsByIdentityId();

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.PAYMENT_TITLE, link: '/payments' },
  ];

  return (
    <PrecancelModalProvider>
      <Layout crumbs={crumbs} title={string_table.PAYMENT_TITLE} subtitle={string_table.SUBSCRIPTION_DESCRIPTION}>
        <Divider classNames={['py-4']} />
        {subscriptionsLoading ? (
          <Spinner data-testid="loading-spinner" blocking color="brand" spinnerSize="xlarge" />
        ) : (
          <>
            <Container classNames={['pt-5']}>
              {subscriptions?.map((s: ISubscriptionResource) => <SubscriptionCard key={s?.id} subscription={s} />)}
            </Container>
          </>
        )}
      </Layout>
    </PrecancelModalProvider>
  );
};

export default Payments;
