import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Divider, Loader, useLoader } from '@legalshield/adonis-ux-framework';
import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

import Memberships from './Memberships/Memberships';
import RealmUtility from '../../utils/realm';
import { EntitlementPresenter } from '../../presenters';
import { IEntitlement } from '../../interfaces/entitlement.interface';
import { Layout } from '../Layout/Layout';
import { ManageAccount } from '../ManageAccount/ManageAccount';
import { useGetEntitlements } from '../../hooks/useEntitlements';

interface BackButtonHeaderProps {
  /**
   * The label for the button
   */
  buttonLabel?: string;
  /**
   * The URL for the button
   */
  url: string;
}

const BackButtonHeader: FC<BackButtonHeaderProps> = ({ buttonLabel, url }) => (
  <Fragment>
    {buttonLabel && <Button label={buttonLabel} onClick={() => (window.location.href = url)} classNames={['mt-4']} />}
    <Divider classNames={['py-4']} />
  </Fragment>
);

const UserOverview: FC = () => {
  const associateFamily = 'Associate';
  const loader = useLoader();
  const [plansPresenters, setPlansPresenters] = useState<EntitlementPresenter[]>([]);
  const [associatesPresenters, setAssociatesPresenters] = useState<EntitlementPresenter[]>([]);
  const { data: entitlements, isLoading, isError, isSuccess } = useGetEntitlements();

  useEffect(() => {
    if (entitlements) {
      buildPresenters(entitlements);
    }
    if (isError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isSuccess) {
      loader.Blank();
    }
  }, [entitlements, isError, isLoading, isSuccess]);

  function buildPresenters(entitlements: IEntitlement[]) {
    const plans: EntitlementPresenter[] = entitlements
      .filter((e) => {
        return e.product.productFamily !== associateFamily;
      })
      .map((e) => new EntitlementPresenter(e));
    const associateAgreements: EntitlementPresenter[] = entitlements
      .filter((e) => {
        return e.product.productFamily === associateFamily;
      })
      .map((e) => new EntitlementPresenter(e));
    setPlansPresenters(plans);
    setAssociatesPresenters(associateAgreements);
  }

  return (
    <Fragment>
      <Loader loaderState={loader.loaderState} />
      <Memberships
        products={plansPresenters}
        associates={associatesPresenters}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </Fragment>
  );
};

const Overview: FC = () => {
  const realm = RealmUtility.getRealm();

  const [pageSubtitle, setPageSubtitle] = useState<string>('');
  const [buttonLabel, setButtonLabel] = useState<string>(null);
  const [buttonHref, setButtonHref] = useState<string>(null);

  useEffect(() => {
    switch (realm) {
      case Realm.GroupAdmin:
        setPageSubtitle(string_table.GROUPS_DESCRIPTION);
        setButtonLabel(string_table.GROUPS_BUTTON);
        setButtonHref('https://groups.' + realmDomain);
        break;
      case Realm.Partner:
        setPageSubtitle(string_table.PARTNER_DESCRIPTION);
        setButtonLabel(string_table.PARTNER_BUTTON);
        setButtonHref('https://partner.' + realmDomain);
        break;
      default:
        setPageSubtitle(string_table.OVERVIEW_DESCRIPTION);
        break;
    }
  }, [realm]);

  return (
    <Layout title={string_table.OVERVIEW_TITLE} subtitle={pageSubtitle}>
      <BackButtonHeader buttonLabel={buttonLabel} url={buttonHref} />
      {realm === Realm.User && <UserOverview />}
      <ManageAccount />
    </Layout>
  );
};

export default Overview;
