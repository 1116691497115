import React, { FC, useEffect, useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, Container, Heading, Modal, Text } from '@legalshield/adonis-ux-framework';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import phone from './phone.svg';
import { themisAnalytics } from '../../../..';

export const IDShieldInfoModal: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const ref = useRef(null)
  const { idShieldOfferExperiment } = useFlags();
  const title = idShieldOfferExperiment.title;
  const copy = idShieldOfferExperiment.copy;
  const phoneNumber = idShieldOfferExperiment.phoneNumber ?? '';
  const linkNumber = phoneNumber.replace(/(\(|\)| |-)/g, '');
  const expiration = idShieldOfferExperiment.expiration;

  useEffect(() => {
    if (showModal && title) {
      themisAnalytics.triggerCustomEvent('view_modal', {
        title,
      });
    }
  }, [showModal, title]);

  return (
    <Modal
      closeButton={true}
      onOpenChange={setShowModal}
      position="center"
      maxWidth="500px"
      maxHeight="80%"
      trigger={
        <Modal.Trigger asChild>
          <Button variant="secondary" label="Learn more" classNames={['mt-4']} />
        </Modal.Trigger>
      }
    >
      <div style={{ overflow: 'auto' }}>
        <Heading as="T26" text={title} />
        <Text as="p" textSize="large" text={copy} />

        <Heading classNames={['mt-5']} as="T20" text="IDShield offers benefits such as:" />
        <Container flexbox>
          <div style={{ flexBasis: '50%' }}>
            <ul className="pl-5 mt-3">
              <li>
                <Text text="3B credit monitoring" />
              </li>
              <li>
                <Text text="Monthly credit score tracking" />
              </li>
              <li>
                <Text text="Social media monitoring" />
              </li>
              <li>
                <Text text="Anti Malware" />
              </li>
              <li>
                <Text text="VPN" />
              </li>
            </ul>
          </div>
          <div style={{ flexBasis: '50%' }}>
            <ul className="pl-5 mt-3">
              <li>
                <Text text="Sex offender monitoring" />
              </li>
              <li>
                <Text text="Reputation management" />
              </li>
              <li>
                <Text text="Password manager" />
              </li>
              <li>
                <Text text="Full-Service Identity Restoration" />
              </li>
            </ul>
          </div>
        </Container>

        <div
          className="p-5"
          style={{
            alignItems: 'center',
            backgroundColor: 'var(--n100)',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <img src={phone} alt="phone" style={{ height: '46px', width: '37px' }} />
          <Heading as="T20" text="Call to sign up or learn more" />
          <a href={`tel:${linkNumber}`} style={{ color: 'unset' }} ref={ref}><Heading as="T20" text={phoneNumber} /></a>
          <Text textSize="medium" text="Sales is available 24/7, 365" style={{ color: 'var(--n500)' }} />
        </div>
      </div>
      <Modal.Actions>
        <Button variant="primary" label="Call now" iconLeft='comm_phone' onClick={() => ref.current?.click()} />
        <Modal.Close asChild>
          <Button variant="secondary" label="Maybe later" />
        </Modal.Close>
      </Modal.Actions>
      {expiration && (
        <Container classNames={['pt-5']} flexbox justifyContent="center">
          <Text textSize="small" text={`Offer expires: ${expiration}`} />
        </Container>
      )}
    </Modal>
  );
};
